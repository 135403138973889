body{
	margin:0px;
	padding:0px;
	font-family: 'Noto Sans JP', sans-serif; 
	overflow-x:hidden;
}

h1,h2,h3,h4,h5,h6{ margin:0px; padding:0px; font-weight:normal;}
p{ margin:0px; padding:0px;}
a{ text-decoration:none; -webkit-transition: all 0.3s linear;
-moz-transition: all 0.3s linear;
-o-transition: all 0.3s linear;
-ms-transition: all 0.3s linear;
transition: all 0.3s linear;}
a:hover, a:focus, a:active{ outline:none; text-decoration:none;}
input:hover, input:focus, input:active{ outline:none;}
ul{ margin:0px; padding:0px;}
ul li{ list-style-type:none;}
img{ border:0px; vertical-align: middle; -webkit-transition: all 0.3s linear;
-moz-transition: all 0.3s linear;
-o-transition: all 0.3s linear;
-ms-transition: all 0.3s linear;
transition: all 0.3s linear;}
.pull-left{ float:left;}
.pull-right{ float:right;}
.clearfix{ clear:both; overflow:hidden;}
.wrapper{ margin:0px auto;}
.container{ max-width:1130px;}

@-webkit-keyframes animate-block-gorizantal-2{50%{-webkit-transform:translateX(5px);transform:translateX(5px)}
0%,100%{-webkit-transform:translateX(0);transform:translateX(0)}
}
@keyframes animate-block-gorizantal-2{50%{-webkit-transform:translateX(5px);transform:translateX(5px)}
0%,100%{-webkit-transform:translateX(0);transform:translateX(0)}
}

.common-title-hp{ display:flex; align-items:flex-end; margin-top:68px; }
.common-title-img-hp{ width:163px; }
.common-title-img-hp img{ width:100%; }
.common-title-text-hp{ padding-left:8px; }
.common-title-hp h2{ font-family: 'Oswald', sans-serif;  font-size:75px; line-height:110px; color:#0F5FBE; font-weight:700; letter-spacing:1.5px; position:relative; display:inline-block; }
.common-title-hp h2:after{ content:''; position:absolute; bottom:22px; right:-75px; background:url(../images/top/title_blue_shape.svg) no-repeat center center; width:170px; height:152px; background-size:100%; }
.common-title-hp h2 span{ position:relative; z-index:1; }
.common-title-hp p{ font-size:36px; line-height:52px; color:#000000; font-weight:700; }
.common-title-center-hp{ text-align:center; justify-content: center; display: inline-block; position:relative; }
.common-title-center-hp .common-title-img-hp{ position:absolute; left:0; top:0; left: -110px; top: -35px; }

.common-title-black-hp h2{ color:#000; }
.common-title-black-hp h2:after{ background:url(../images/top/title_yellow_shape.svg) no-repeat center center; background-size:100%; }
.common-title-reverse-hp h2:after{ background:url(../images/top/title_blue_shape_reverse.svg) no-repeat center center; background-size:100%; }

.common-btn-hp{ position:relative; min-width:324px; font-size:16px; line-height:32px; padding:8px 30px; color:#fff; display:inline-block; text-align:center; border:1px solid #fff; font-weight:700; }
.common-btn-hp img{ position:absolute; right:10px; width:8px; top:calc(50% - 7px);  }
.common-btn-hp:hover{ background:#000; color:#fff; border-color:#000; }
.common-black-btn-hp{ background:#000; border-color:#000; }
.common-black-btn-hp:hover{ opacity:0.8; }

.common-yellow-btn-hp{ background:#FFD50E; border-color:#FFD50E; color:#000; }
.common-yellow-btn-hp:hover{background:#FFD50E; border-color:#FFD50E; color:#000; opacity:0.8; }

.common-yellow-hp{ position: relative; font-size: 30px;  line-height: 44px; padding: 14px 35px 14px 35px; color: #000; display: inline-block; font-weight:700; min-width: 640px; text-align: center; }
.common-yellow-hp span{ z-index: 1; position: relative; }
.common-yellow-hp h2{ z-index: 1; position: relative;  font-weight:700; font-size: 30px;  line-height: 44px; }
.common-yellow-hp:after{ content: ''; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: #FFD50E; transform: skew(-22deg); -webkit-transform: skew(-22deg); -moz-transform: skew(-22deg); }

.common-black-hp{ position: relative; font-size: 24px; line-height: 24px; padding: 8px 35px 8px 35px; color: #fff; display: inline-block; font-weight:700; min-width: 210px; text-align: center; }
.common-black-hp span{ z-index: 1; position: relative; }
.common-black-hp:after{ content: ''; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: #000; transform: skew(-22deg); -webkit-transform: skew(-22deg); -moz-transform: skew(-22deg); }

.common-yellow-hp h2 span{ font-size:16px; line-height:32px; font-weight:400; }

/*--------------- HEADER_START --------------*/
#header{ padding:0px; margin:0px;  position:fixed; width:100%; top:0; z-index:9999; background:#000000; }
.header-in-hp{ display: flex; align-items: center; height:100px; padding:0 0 0 25px; justify-content: space-between; }
.logo-hp{ float:left; padding:0; width: 260px; }
.logo-hp img{ width:100%; vertical-align:middle; }
.navigation{ display:flex; align-items: center; }
.navigation > ul{ text-align:left; width:100%; padding:0 0 0 0; }
.navigation > ul > li{ font-family: 'Oswald', sans-serif; font-size: 20px; line-height: 26px; display:inline-block; font-weight:600; padding:0 25px; vertical-align:middle; text-align:center; }
.navigation > ul > li span{ font-family: 'Noto Sans JP', sans-serif; font-size:16px; line-height:16px; font-weight:700; }
.navigation > ul > li > a{ color: #fff; padding:0px 0;  display:block; }
.navigation > ul > li > a:hover, .navigation > ul > li > a:focus{ color:#FFD50E;}
.navigation > ul > .current  > a, .navigation > ul > .current  > a:hover, .navigation > ul > .current  > a:focus{ color:#FFD50E; background:none; }
.navigation > ul > li.contact-hp{ background:#134D93; padding:0px; position:relative; }
.navigation > ul > li.contact-hp:after{ content:''; position:absolute; left:0; top:0; width: 0; height: 0; border-top: 100px solid #0F5FBE; border-right: 152px solid transparent; }
.navigation > ul > li.contact-hp > a{ padding:24px 40px; position:relative; z-index:1; }
.navigation > ul > li.contact-hp > a:hover{ color:#FFD50E; }
.navigation > ul > li.contact-hp > a:focus{ color:#FFD50E; }
.mobile-menu-icon-hp{ margin:0px; padding:0px; float:right; display:none;  width:35px; height:60px; text-align:center; cursor:pointer; }
.menu-toggle-btn{ margin:21px 0px 20px 0px; cursor:pointer; width:35px; height:26px; padding:0; position:relative; z-index:999999999; }
.menu-toggle-btn span{ background:rgba(255,255,255,0.8); display:block; width:35px; height:2px; border-radius:0px; margin-bottom:10px; -webkit-transition: all 0.5s linear; transition: all 0.3s linear;}
.menu-toggle-btn span:last-child{ margin-bottom:0px;}
.menu-toggle-btn.open span:nth-child(1), .menu-toggle-btn.open span:nth-child(3){ transform: translate(0px, 8px) rotate(-45deg) scalex(1.3); margin:0;}
.menu-toggle-btn.open span:nth-child(2){ height:0; margin:0;}
.menu-toggle-btn.open span:nth-child(3){ transform: translate(0px, 6px) rotate(45deg) scalex(1.3);}
.overlay-mobile-menu-hp{ position:fixed; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.6); z-index: 999; display:none; } 
/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
#contain{ margin:0px; padding:100px 0 0 0; overflow:hidden; }

.contact-block-hp{ }
.contact-block-overlay-hp{ background:rgba(0,0,0,0.2); }
.contact-block-in-hp{ height:256px; display:flex; align-items:center; }
.contact-box-hp{ margin:auto; text-align:center; }
.contact-title-hp{ font-size:32px; line-height:46px; color:#fff; font-weight:700; }
.contact-btn-hp{ padding:35px 0 0 0; text-align:center; }
.phone-block-hp{ background:#0F5FBE; padding:25px 0; }
.phone-block-in-hp{  }
.phone-middle-hp{ font-size:0px; text-align:center; }
.phone-text-hp{ font-size:20px; line-height:29px; color:#fff; font-weight:700; display:inline-block; vertical-align:middle; }
.phone-number-hp{ font-size:36px; line-height:30px; color:#fff; font-weight:700; padding:0 45px; display:inline-block; vertical-align:middle; }
.phone-number-hp img{ vertical-align:middle; margin:-6px 0 0 0; }
.phone-time-hp{ font-size:16px; line-height:24px; color:#fff; border:1px solid #fff; padding:2px 19px 4px 19px; border-radius:15px; display:inline-block; vertical-align:middle; }

.recruit-middle-hp{ display: flex; flex-wrap: wrap; justify-content: flex-start; padding: 0; list-style-type: none; margin:65px 0 0 0; }
.recruit-box-hp{ width:calc(50% - 16px); margin-right:32px; float:left; }
.recruit-box-hp a{ display:block; height:100%; }
.recruit-box-hp:nth-child(2n){ margin-right:0px; }
.recruit-box-overlay-hp{ background:rgba(0,0,0,0.3); padding:48px 0; height:100%; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -o-transition: all 0.3s linear; -ms-transition: all 0.3s linear; transition: all 0.3s linear; }
.recruit-box-hp a:hover .recruit-box-overlay-hp{ background:rgba(0,0,0,0.6);  }
.recruit-box-in-hp{ width:440px; margin-right:94px; border:2px solid #fff; min-height:462px; padding:115px 80px 20px 80px; float:right; }
.recruit-box-hp:nth-child(2n) .recruit-box-in-hp{ float:left; margin-left:94px; margin-right:0px; }
.recruit-title-hp{ text-align:center; font-size:32px; line-height:32px; color:#fff; font-weight:700; }
.recruit-desc-hp{ color:#fff; font-size:16px; line-height:32px; padding:50px 0 0 0; font-weight:700; }
.recruit-btn-hp{ padding:45px 0 45px 0; text-align:center; }
/*--------------- CONTAIN_END --------------*/

/*--------------- FOOTER_START --------------*/
#footer{ margin:0px; padding:0px 0 0px 0; overflow:hidden; background:#000; }
.back-to-top-hp{ position:fixed; right: 0vw; bottom: 18vw; z-index: 99; display:none; }
.back-to-top-hp a{ display:block;  }
.footer-top-hp{ padding:30px 0 10px 0; }
.footer-left-hp{ width:50%; float:left; }
.footer-logo-hp{ width:300px; padding-bottom:30px; }
.footer-logo-hp img{ width:100%; }
.footer-address-hp{ font-size:14px; line-height:20px; color:#fff; padding-bottom:15px; }
.footer-address-hp a{ color:#fff; }
.footer-right-hp{ width:50%; float:right; }
.footer-link-hp{ text-align:right; }
.footer-link-hp ul{ display:inline-block; max-width:154px; width:calc(33.33% - 3px); vertical-align:top; text-align:left; }
.footer-link-hp ul li{ font-size:16px; line-height:24px; padding-bottom:14px; }
.footer-link-hp ul li a{ color:#fff; }
.footer-bottom-hp{ border-top:1px solid #707070; padding:10px 0; }
.footer-bottom-left-hp{ width:50%; float:left; font-size:12px; line-height:17px; color:#fff; font-weight:700; }
.footer-bottom-left-hp a{ color:#fff; }
.footer-bottom-right-hp{ width:50%; float:right; text-align:right; font-size:12px; line-height:17px; color:#fff; font-weight:700; }
/*--------------- FOOTER_END --------------*/

/*--------------- SERVICE_START --------------*/
.banner-block-sp{}
.banner-block-overlay-sp{ background:rgba(0,0,0,0.3) url(../images/services/banner_shape.svg) no-repeat right top; background-size:auto 100%; }
.banner-block-in-sp{ display:flex; align-items:center; height:222px;  }
.banner-middle-sp{ }
.banner-middle-sp h1{ font-family: 'Oswald', sans-serif; font-size:60px; color:#fff; font-weight:700; line-height:60px; }
.banner-middle-sp p{ font-size:24px; line-height:35px; color:#fff; font-weight:700; padding-top:10px;  }
.common-yellow-36-hp{ font-size:36px; }
.common-yellow-36-hp h2{ font-size:36px; }
.common-box-title-sp{ font-size: 30px; line-height: 56px; color: #000; font-weight: 700; border-bottom: 4px solid #000; display: inline; padding-bottom: 5px; }
.common-box-title-sp h3{ font-size: 30px; line-height: 56px; font-weight: 700; display: inline; }
.common-box-desc-sp{ padding:25px 0 0 0; font-size:16px; line-height:32px; color:#000; }

.manufacturer-block-sp{ padding:45px 0 90px 0; position:relative; }
.manufacturer-block-sp:after{ content:''; position:absolute; left:-14px; top:-19px; background:url(../images/services/service_shape_left.svg) no-repeat top center; width:147px; height:223px; background-size:100%; }
.manufacturer-block-sp:before{ content:''; position:absolute; right:-18px; top:-25px; background:url(../images/services/service_shape_right.svg) no-repeat top center; width:195px; height:296px; background-size:100%; }
.manufacturer-block-in-sp{ position:relative; z-index:1; }
.manufacturer-middle-sp{ text-align:center; }
.manufacturer-desc-hp{ padding:60px 30px 0 30px; font-size:20px; line-height:40px; color:#000; font-weight:700; }

.advantage-block-op:after{ display:none; }
.advantage-block-op:before{ display:none; }
/*--------------- SERVICE_END --------------*/

/*--------------- COMPANY_START --------------*/
.company-title-cp{ text-align:center; }
.table-data-cp{ max-width:900px; margin:auto; }
.table-data-list-cp{ display:table; width:100%; }
.table-data-left-cp{ width:180px; background:#F5F5F5; border:1px solid #D2D2D2; display:table-cell; vertical-align:middle; font-size:16px; line-height:32px; color:#000; text-align:center; padding:14px 10px; font-weight:500; }
.table-data-right-cp{ width:calc(100% - 180px);  background:#fff; border:1px solid #D2D2D2; display:table-cell; vertical-align:middle; padding:14px 30px; font-size:16px; line-height:32px; color:#000; }

.manufacturer-block-rp1{ padding-bottom:50px; }

.interview-middle-rp{ padding:50px 0 0 0; text-align:center; }
.interview-box-rp{ display:inline-block; vertical-align:top; padding:0 50px; }
.interview-box-img-rp{ width:200px; margin:auto; padding-bottom:20px; }
.interview-box-img-rp img{ width:100%; border-radius:100%; }
/*--------------- COMPANY_END --------------*/

/*--------------- CONTACT_START --------------*/
.form-flow-cp{ padding-bottom:50px; }
.form-flow-cp ul{ text-align:center; }
.form-flow-cp ul li{ display:inline-block; font-size:16px; line-height:32px; padding:0 53px; position:relative; }
.form-flow-cp ul li a{ color:#000;  }
.form-flow-cp ul li a.current{ color:#0F5FBE; }
.form-flow-cp ul li:after{ content:''; position:absolute; width:64px; height:1px; background:#000; top:calc(50% + 1px); right:-34px; }
.form-flow-cp ul li:last-child:after{ display:none; }

.form-field-cp{ padding-bottom:30px; }
.form-field-label-cp{ width:230px; float:left; padding-right:15px; font-size:16px; line-height:32px; color:#000; }
.form-field-label-cp span{ color:#FF0000; }
.form-field-input-cp{ width:calc(100% - 230px); float:left; }
.form-field-checkbox-cp{ width:calc(100% - 230px); float:left; padding:6px 0 0 18px; }
.form-field-input-cp input{ background:#F5F5F5; border:1px solid #707070; height:33px; padding:0 15px; font-size:14px; width:100%; }
.form-field-326-cp input{ max-width:326px; }
.form-field-474-cp input{ max-width:474px; }
.form-field-214-cp input{ max-width:214px; }
.form-field-input-cp textarea{ background:#F5F5F5; border:1px solid #707070; height:230px; padding:15px; font-size:14px; width:100%; }
.form-term-cp{ font-size:16px; line-height:24px; color:#000; padding-top:10px; }

.formcheck { display: block; position: relative; padding-left: 32px; margin-bottom: 16px; cursor: pointer; font-size: 16px; line-height:25px;  font-weight:400; color:#000; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.formcheck:last-child{ margin-bottom:0px; }
.formcheck input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.checkmark { position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #F5F5F5; border:1px solid #707070; border-radius: 50%;  }
.formcheck:hover input ~ .checkmark { background-color: #F5F5F5;  border:1px solid #707070; border-radius: 50%; }
.formcheck input:checked ~ .checkmark {
	 background-color: #F5F5F5; border:1px solid #707070; 
	 border-radius: 50%;
}
.checkmark:after { content: ""; position: absolute; display: none; }
.formcheck input:checked ~ .checkmark:after { display: block; }
.formcheck .checkmark:after { left: 8px; top: 4px; width: 7px; height: 12px; border: solid #000; border-width: 0 2px 2px 0; -webkit-transform: rotate(45deg);  -ms-transform: rotate(45deg); transform: rotate(45deg); }
.form-term-cp .checkmark,
.form-term-cp .formcheck:hover input ~ .checkmark,
.form-term-cp .formcheck input:checked ~ .checkmark {
	border-radius: unset;
}
/*--------------- CONTACT_END --------------*/




